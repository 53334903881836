.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-color {
  background-color: #2c86c9;
}
.img-logo {
  width: 107px;
  height: 76px;
}
.save-btn {
  width: 48px;
  height: 48px;
}

.pdf-btn {
  width: 150px;
  height: 48px;
}
.left-side {
  background-color: #fdfcfc;
  width: 100%;
  overflow-y: scroll;
}
.right-side {
  width: 100%;
  background-color: #ededed;
  height: 100%;
}
.input-colors {
  background-color: #f2f2f2;
  color: #282c34;
}
.text-title {
  color: #3d3d3d;
  font-weight: bold;
}
.btn-dark {
  background-color: #3c3c3c;
}
.textarea-about {
  height: 148px;
  resize: none;
}
.right-input {
  margin-left: 10px;
}

.img-profile {
  margin-left: 10px;
  margin-top: 30px;
  background-image: url(../public/assets/images/loading.png);
  background-size: cover;
  height: 126px;
}
.border-img {
  border: 2px solid #3e3e3e;
  margin-left: 10px;
  margin-top: 13px;
}
.img-text {
  color: #707070;
  font-size: 12px;
  text-align: center;
}
.box-border {
  border: 1px solid #e8e8e8;
  padding-top: 15px;
}
.input-groups {
  width: 80%;
}
.range-input {
  margin-top: 50px;
}
.progress-color {
  background-color: #dc4d53;
}
.text-progress {
  color: #707070;
}
.percentage-progress {
  color: #dc4d53;
}
.result-cv {
  background-image: url(../public/assets/images/loading-result.png);
  background-size: cover;
  height: 50vh;
  width: 80%;
  filter: blur(2px);
  background-color: #dc4d53;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="range"]::-webkit-slider-thumb {
    width: 15px;
    -webkit-appearance: none;
    appearance: none;
    height: 15px;
    cursor: ew-resize;
    background: #fff;
    box-shadow: 0px 0 0 1px #605e5c;
    border-radius: 50%;
  }
}

@media only screen and (min-width: 375px) and (max-width: 540px) {
  .img-text {
    display: none;
  }
  .img-profile {
    position: relative;
  }
  .img-div {
    position: absolute;
  }
  .border-img {
    margin-left: 10px;
    margin-top: 11px;
    width: 90%;
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}